@import url(https://fonts.googleapis.com/css2?family=Poppins%3Awght%40400%3B500&display=swap%27%29%3B);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
footer {
  background-color: rgb(3, 4, 7);
  position: relative;
  bottom: 0;
  top: 460px;
  width: 100%;
  color: white;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #ececec;
}

div .radio {
    border-radius: 20px;
}

div .radio-c {
    border-radius: 40px;
}

/*Main container*/

.box-area {
    width: 930px;
}



/*Main login*/

/*Left box*/

/*Rigth box*/
.right-box {
    padding: 40px 30px 40px 40px;
}

/*Placeholder*/
::placeholder {
    font-size: 16px;
}

/*Responsive*/
@media only screen and (max-width: 768px) {
    
    .box-area {
        margin: 0 10px;

    }

    

    .right-box{
        padding: 20px;
    }
}
.fondo {
    background-color: lightgray;
}
.borde {
    border-radius: 0px 0px 10px 10px;
   
}

.centrar {
    display: flexbox;
    align-items: center;
    justify-content: center;
}
.negrita {
    font-weight: bold;
    color: aliceblue;
}

.bord {
    border: solid 0.5px black;
}

.celeste  {
    background-color: #2D9BF0;
}
.celesteBtn {
    background-color: #2D9BF0;
    border-radius: 5px;
    border: none;
    color:aliceblue;
    padding: 5px 10px 5px 10px;
} 